import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'
// import pic05 from '../assets/images/pic05.jpg'
// import pic06 from '../assets/images/pic06.jpg'
import Banner from '../components/Banner'
import Layout from '../components/layout'

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="Keefer Construction"
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
        ></Helmet>

        <Banner />

        <div id="main">
          <section id="one" className="tiles">
            <article style={{ backgroundImage: `url(${pic01})` }}>
                <header className="major">
                  <h3>PLANNING</h3>
                  <p>Custom Plans</p>
                </header>
            </article>
            <article style={{ backgroundImage: `url(${pic02})` }}>
                <header className="major">
                  <h3>BUILDING</h3>
                  <p>Top Quality Building</p>
                </header>
            </article>
            <article style={{ backgroundImage: `url(${pic03})` }}>
                <header className="major">
                  <h3>DESIGN</h3>
                  <p>Specifically Tailored Designs</p>
                </header>
            </article>
            <article style={{ backgroundImage: `url(${pic04})` }}>
                <header className="major">
                  <h3>COLLABORATION</h3>
                <p>Collaborate with our industry leaders every step of the way</p>
                </header>
            </article>
            {/* 
            <article style={{ backgroundImage: `url(${pic05})` }}>
                <header className="major">
                  <h3>Consequat</h3>
                  <p>Ipsum dolor sit amet</p>
                </header>
            </article>
            <article style={{ backgroundImage: `url(${pic06})` }}>
                <header className="major">
                  <h3>Etiam</h3>
                  <p>Feugiat amet tempus</p>
                </header>
            </article> */}
          </section>
          <section id="two">
            <div className="inner">
              <header className="major">
                <h2>ABOUT</h2>
              </header>
              <p>
                Keefer Construction Unlimited is a construction company that was recently established in 2020. Throughout its multiple decades in the industry, it was able to work with different architects and designers on many custom residential renovations and completed each project successfully. Its services involve management of preconstruction and construction projects. Today, it has accommodated more and more clients around the Los Angeles area.
              </p>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default HomeIndex
