import React from 'react'

function encode(data) {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

const Contact = (props) => {

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                'email': document.getElementById('email').value,
                'message': document.getElementById('message').value,
                'name': document.getElementById('name').value,
            }),
        });

        document.getElementById('email').value = ""
        document.getElementById('message').value = ""
        document.getElementById('name').value = ""
        
    }

    return (
    <section id="contact">
        <div className="inner">
            <section>
                    <form
                        action="/contact/"
                        method="POST"
                        name="contact"
                        data-netlify="true"
                        onSubmit={handleSubmit}
                    >
                    <input type="hidden" name="form-name" value="contact" />
                    <div className="field half first">
                        <label htmlFor="name">Name</label>
                        <input type="text" name="name" id="name" />
                    </div>
                    <div className="field half">
                        <label htmlFor="email">Email</label>
                        <input type="text" name="email" id="email" />
                    </div>
                    <div className="field">
                        <label htmlFor="message">Message</label>
                        <textarea name="message" id="message" rows="6"></textarea>
                    </div>
                    <ul className="actions">
                        <li><button type="submit" className="special">Send Message</button></li>
                        <li><button type="reset">Clear</button></li>
                    </ul>
                </form>
            </section>
            <section className="split">
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-envelope"></span>
                        <h3>Email</h3>
                        <a href="#">keeferconstruction.com</a>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-phone"></span>
                        <h3>Phone</h3>
                        <span>(747) 242-4041</span>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-home"></span>
                        <h3>Address</h3>
                        <span>6171 Jared Ct<br />
                        Woodland Hills, CA 91367<br />
                        United States of America</span>
                    </div>
                </section>
            </section>
        </div>
    </section>
)}

export default Contact
